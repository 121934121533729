<template>
  <div class="lyt-grid lyt-grid-magazine my-8">
    <div class="lyt-grid-item idx-0">
      <Img class="image" :="image" />
    </div>
    <div class="lyt-grid-item idx-1">
      <div>
        <h2 class="title card-title text-4xl">
          <LinkTo v-if="path" :to="path">{{ label }}</LinkTo>
          <span v-else>{{ label }}</span>
        </h2>
        <Wysiwyg v-if="body" class="summary my-2" :="body" />
        <slot name="path">
          <LinkTo v-if="path" class="button my-4" :to="path">
            {{ t('View Current Issue') }}
          </LinkTo>
        </slot>
        <aside v-if="$slots.aside">
          <slot name="aside" />
        </aside>
      </div>
    </div>
    <div v-if="$slots.promotion" class="lyt-grid-item idx-2">
      <slot name="promotion" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ContentBody, ContentImage } from '~/types'
defineProps<{
  label: string
  path?: string
  body?: ContentBody
  image: ContentImage
}>()

const { t } = useI18n()
</script>

<style lang="postcss" scoped>
.title {
  view-transition-name: title;
}

.image {
  view-transition-name: image;
}
</style>

<i18n lang="json">
{
  "en": {
    "View Current Issue": "View Current Issue"
  },
  "es": {
    "View Current Issue": "Ver la edición actual"
  }
}
</i18n>
